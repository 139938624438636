<template>
  <VisitorWrapper>
    <CContainer>
      <CRow
        v-if="!isSchoolSettingsLoading"
        class="justify-content-center mt-4 bg-white rounded-30 p-4 d-flex flex-column gap-2"
      >
        <!-- SERVER ERROR -->
        <VisitorErrorHandler
          v-if="serverError != null"
          :error="serverError"
          @done="serverError = null"
        />
        <!-- SERVER ERROR -->

        <VisitorMatrixSetting />
      </CRow>
      <CRow
        v-else
        class="d-flex justify-content-center align-content-center mt-4 bg-white rounded-30 p-4 gap-2"
      >
        <CSpinner color="primary" />
      </CRow>
    </CContainer>
  </VisitorWrapper>
</template>
<script>
import VisitorErrorHandler from "@/v3components/VisitorErrorHandler.vue"
import VisitorWrapper from "@/v3components/VisitorWrapper.vue"
import VisitorMatrixSetting from "@/v3components/Forms/Visitor/GuardianStudentMatrix/VisitorMatrixSetting.vue"
import { onMounted, ref } from "vue"
import { useStore } from "vuex"

export default {
  name: "VisitorGuardianStudentMatrix",
  components: {
    VisitorMatrixSetting,
    VisitorWrapper,
    VisitorErrorHandler
  },
  setup() {
    const store = useStore()
    const serverError = ref(null)
    const isSchoolSettingsLoading = ref(true)

    onMounted(async () => {
      try {
        isSchoolSettingsLoading.value = true
        await store.dispatch("visitorSettings/getSchoolSettings")
      } catch (error) {
        serverError.value = error
      } finally {
        isSchoolSettingsLoading.value = false
      }
    })

    return {
      isSchoolSettingsLoading,
      serverError
    }
  }
}
</script>

<style scoped>
.pt-2\.5 {
  padding-top: 0.72rem;
}
</style>
