<template>
  <VisitorWrapper>
    <CContainer>
      <CRow
        v-if="!isLoading"
        class="justify-content-center mt-4 bg-white rounded-30 p-4 d-flex flex-column gap-4"
      >
        <!-- SERVER ERROR -->
        <VisitorErrorHandler
          v-if="serverError != null"
          :error="serverError"
          @done="serverError = null"
        />
        <!-- SERVER ERROR -->

        <!-- SETTINGS HEADER -->
        <div>
          <h3>Manage printer</h3>
          <p>Add a new printer or manage an existing printer</p>
        </div>
        <!-- SETTINGS HEADER -->

        <!-- WHEN NO PRINTER -->
        <div
          v-if="hasNoPrinter"
          class="d-flex justify-content-start align-content-center gap-4"
        >
          <BaseButton solid rounded @click="onAddPrinter">
            Add Printer
          </BaseButton>
          <BaseButton rounded @click="onSetup">Setup Instructions</BaseButton>
        </div>
        <!-- WHEN NO PRINTER -->

        <!-- WHEN HAS PRINTER -->
        <div
          v-else
          class="d-flex justify-content-between align-content-center gap-2"
        >
          <h3>
            {{ printer?.printerName || "No printer selected" }}
          </h3>
          <div class="d-flex justify-content-end gap-2">
            <BaseButton rounded @click="onTestPrinter">
              {{ testPrinterAction }}
            </BaseButton>
            <BaseButton solid rounded @click="onDeletePrinter">
              Delete
            </BaseButton>
          </div>
        </div>
        <!-- WHEN HAS PRINTER -->
      </CRow>
      <CRow
        v-else
        class="d-flex justify-content-center align-content-center mt-4 bg-white rounded-30 p-4 gap-2"
      >
        <CSpinner color="primary" />
      </CRow>
    </CContainer>
  </VisitorWrapper>
</template>
<script>
import VisitorErrorHandler from "@/v3components/VisitorErrorHandler.vue"
import VisitorWrapper from "@/v3components/VisitorWrapper.vue"
import BaseButton from "@/v3components/shared/Buttons/BaseButton.vue"
import visitorConstants from "@/constants/visitorConstants"
import visitorConfigs from "@/configs/visitorConfigs"
import passHelpers from "@/helpers/index"
import { useStore } from "vuex"
import { computed, ref, onMounted, inject, defineAsyncComponent } from "vue"
import moment from "moment-timezone"

const { VISITOR_LOCAL_STORAGE_KEYS } = visitorConstants

const VisitorPrintPreviewModal = defineAsyncComponent(
  () =>
    import("@/v3components/shared/VisitorPrint/VisitorPrintPreviewModal.vue")
)

const VisitorAddPrinterForm = defineAsyncComponent(
  () =>
    import(
      "@/v3components/Forms/Visitor/PrinterSettings/VisitorAddPrinterForm.vue"
    )
)

const VisitorSetupPrinterForm = defineAsyncComponent(
  () =>
    import(
      "@/v3components/Forms/Visitor/PrinterSettings/VisitorSetupPrinterForm.vue"
    )
)

const VisitorDymoPrinterStatusCheck = defineAsyncComponent(
  () =>
    import(
      "@/v3components/Forms/Visitor/PrinterSettings/VisitorDymoPrinterStatusCheck.vue"
    )
)

export default {
  name: "VisitorPrinterSetup",
  components: {
    BaseButton,
    VisitorWrapper,
    VisitorErrorHandler
  },
  setup() {
    const modal = inject("modal")
    const store = useStore()
    const isLoading = ref(true)
    const serverError = ref(null)

    const printerList = [
      {
        printerImage: visitorConstants.PRINTER_CONFIG.BROTHER.IMAGE,
        printerName: visitorConstants.PRINTER_CONFIG.BROTHER.NAME,
        printerBrand: visitorConstants.PRINTER_CONFIG.BROTHER.BRAND
      },
      {
        printerImage: visitorConstants.PRINTER_CONFIG.DYMO.IMAGE,
        printerName: visitorConstants.PRINTER_CONFIG.DYMO.NAME,
        printerBrand: visitorConstants.PRINTER_CONFIG.DYMO.BRAND
      }
    ]

    const activeSchool = computed(
      () => store?.getters?.["schools/activeSchool"] || null
    )

    const schoolName = computed(() => activeSchool?.value?.name || "")

    const printer = computed(() => store.getters["visitorSettings/printer"])

    const isBrotherPrinterConfigured = computed(() => {
      return (
        printer?.value?.printerBrand ===
        visitorConstants.PRINTER_CONFIG.BROTHER.BRAND
      )
    })

    const testPrinterAction = computed(() => {
      return isBrotherPrinterConfigured.value
        ? "Test badge print"
        : "Check printer connection"
    })

    const hasNoPrinter = computed(
      () => store.getters["visitorSettings/hasNoPrinter"]
    )

    const onTestPrinter = () => {
      const printerBrand = printer?.value?.printerBrand || ""
      if (printerBrand == visitorConstants.PRINTER_CONFIG.BROTHER.BRAND) {
        const payload = {
          schoolName: schoolName?.value,
          firstName: "John H.",
          lastName: "Smith",
          reasonForVisit: "Visit Reason",
          date: visitorConfigs?.useCustomVisitorTimeFunction
            ? passHelpers.convertToCurrentTimezone(
                moment(),
                false,
                "MMM DD, YYYY - h:mm A"
              )
            : moment().format("MMM DD, YYYY - h:mm A"),
          imageURL: "/img/avatars/user-avatar.jpeg",
          destination: "Visit Destination"
        }
        modal.open(VisitorPrintPreviewModal, {
          size: "lg",
          title: visitorConstants.PRINTER_SETUP.PRINTER_PREVIEW_MODAL_TITLE,
          props: {
            badge: payload
          }
        })
      } else {
        modal.open(VisitorDymoPrinterStatusCheck, {
          size: "lg",
          title: "Printer Status",
          props: {
            data: {
              printerName: printer?.value?.printerName,
              printerBrand: printer?.value?.printerBrand
            }
          }
        })
      }
    }

    const onDeletePrinter = async () => {
      try {
        serverError.value = null
        isLoading.value = true
        await store.dispatch(
          "visitorSettings/deletePrinter",
          printer?.value?.printerId ||
            localStorage.getItem(VISITOR_LOCAL_STORAGE_KEYS.PRINTER_ID) ||
            ""
        )
        store.commit("visitorSettings/SET_PRINTER", {
          id: "",
          printer_name: "",
          printer_brand: "",
          fingerprint: ""
        })
      } catch (error) {
        serverError.value = error
      } finally {
        isLoading.value = false
      }
      localStorage.removeItem(VISITOR_LOCAL_STORAGE_KEYS.PRINTER_ID)
      localStorage.removeItem(VISITOR_LOCAL_STORAGE_KEYS.PRINTER_NAME)
      localStorage.removeItem(VISITOR_LOCAL_STORAGE_KEYS.PRINTER_BRAND)
    }

    const onEditPrinter = async (newPrinter) => {
      const data = {
        printer_brand: newPrinter?.printerBrand,
        printer_name: newPrinter?.printerName,
        fingerprint:
          printer?.value?.printerFingerprint ||
          localStorage.getItem(
            VISITOR_LOCAL_STORAGE_KEYS.PRINTER_FINGERPRINT
          ) ||
          ""
      }
      try {
        serverError.value = null
        isLoading.value = true
        await store.dispatch("visitorSettings/updatePrinter", data)
        await store.dispatch("visitorSettings/getPrinters")
      } catch (error) {
        serverError.value = error
      } finally {
        isLoading.value = false
      }
      localStorage.setItem(
        VISITOR_LOCAL_STORAGE_KEYS.PRINTER_ID,
        printer?.value?.printerId
      )
      localStorage.setItem(
        VISITOR_LOCAL_STORAGE_KEYS.PRINTER_NAME,
        newPrinter?.printerName
      )
      localStorage.setItem(
        VISITOR_LOCAL_STORAGE_KEYS.PRINTER_BRAND,
        newPrinter?.printerBrand
      )
    }

    const onAddPrinter = () => {
      modal.open(VisitorAddPrinterForm, {
        size: "lg",
        title: "Add Printer",
        props: {
          printerList,
          onEditPrinter
        }
      })
    }

    const onSetup = () => {
      modal.open(VisitorSetupPrinterForm, {
        size: "lg",
        title: "Setup Instructions",
        props: {
          printerList
        }
      })
    }

    onMounted(async () => {
      try {
        isLoading.value = true
        await store.dispatch("visitorSettings/getPrinters")
      } catch (error) {
        serverError.value = error
      } finally {
        isLoading.value = false
      }
      localStorage.setItem(
        VISITOR_LOCAL_STORAGE_KEYS.PRINTER_ID,
        printer?.value?.printerId
      )
      localStorage.setItem(
        VISITOR_LOCAL_STORAGE_KEYS.PRINTER_NAME,
        printer?.value?.printerName
      )
      localStorage.setItem(
        VISITOR_LOCAL_STORAGE_KEYS.PRINTER_BRAND,
        printer?.value?.printerBrand
      )
    })

    return {
      isLoading,
      serverError,
      printer,
      hasNoPrinter,
      printerList,
      onTestPrinter,
      testPrinterAction,
      onDeletePrinter,
      onEditPrinter,
      onAddPrinter,
      onSetup
    }
  }
}
</script>
