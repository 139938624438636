<template>
  <div class="c-app flex-row align-items-center">
    <div class="v3verify-email-view">
      <VerifyEmailForm />
    </div>
  </div>
</template>

<script>
import VerifyEmailForm from "@/v3components/Forms/VerifyEmailForm"

export default {
  name: "VerifyEmail",
  components: { VerifyEmailForm }
}
</script>
