<template>
  <VisitorWrapper>
    <CContainer v-if="!isGeneralSettingsLoading">
      <!-- SERVER ERROR -->
      <VisitorErrorHandler
        v-if="generalSettingsServerError != null"
        :error="generalSettingsServerError"
        @done="generalSettingsServerError = null"
      />
      <!-- SERVER ERROR -->

      <!-- GENERAL SETTING LIST -->
      <CRow
        v-for="setting in settingList"
        class="justify-content-center mt-4 bg-white rounded-30 p-4 d-flex flex-column gap-2"
        :class="{
          'visitor-loading': isLoading
        }"
        :key="getSettingsKey(setting.settingsKey)"
      >
        <CCol md="12 text-start">
          <!-- SETTINGS CARD -->
          <VisitorSettingsControl
            :settings-key="setting.settingsKey"
            :heading="setting.heading"
            :description="setting.description"
            :show-description="setting.showDescription"
            :has-learn-more="setting.hasLearnMore"
            :learn-more="setting.learnMore"
            :has-edit="setting.hasEdit"
            :show-edit="setting.showEdit"
            :has-switch="setting.hasSwitch"
            :show-switch="setting.showSwitch"
            :options="setting.options"
            :edit="setting.edit"
            :toggle="setting.toggle"
            :error="
              setting.settingsKey === activeSettingKey ? serverError : null
            "
          >
            <!-- SETTING CARD CONTROL - RIGHT SECTION -->
            <template v-if="setting.hasControl" #control>
              <component
                v-if="setting.settingsKey === 'school-hours'"
                :is="VisitorSchoolHours"
                :settings-key="setting.settingsKey"
                :heading="setting.heading"
                :description="setting.description"
                :has-learn-more="setting.hasLearnMore"
                :learn-more="setting.learnMore"
                :has-edit="setting.hasEdit"
                :show-edit="setting.showEdit"
                :has-switch="setting.hasSwitch"
                :show-switch="setting.showSwitch"
                :options="setting.options"
                :edit="setting.edit"
                :toggle="setting.toggle"
              />
            </template>
            <!-- SETTING CARD CONTROL - RIGHT SECTION -->

            <!-- SETTING CARD BODY - REASONS, DESTINATIONS, HEALTH QUESTIONS -->
            <template
              v-if="setting?.hasBody && setting?.options?.enabled"
              #default
            >
              <VisitorSettingsBody
                v-if="setting?.hasBody && setting?.options?.enabled"
                :settings-key="setting.settingsKey"
                :heading="setting.heading"
                :description="setting.description"
                :has-learn-more="setting.hasLearnMore"
                :learn-more="setting.learnMore"
                :has-edit="setting.hasEdit"
                :show-edit="setting.showEdit"
                :has-switch="setting.hasSwitch"
                :show-switch="setting.showSwitch"
                :options="setting.options"
                :edit="setting.edit"
                :toggle="setting.toggle"
              />
            </template>
            <!-- SETTING CARD BODY - REASONS, DESTINATIONS, HEALTH QUESTIONS -->
          </VisitorSettingsControl>
          <!-- SETTINGS CARD -->
        </CCol>
      </CRow>
      <!-- GENERAL SETTING LIST -->
    </CContainer>
    <CContainer v-else>
      <CRow
        v-for="n in 8"
        :key="n"
        class="justify-content-center align-content-center mt-4 bg-white rounded-30 p-6 d-flex gap-2"
      >
        <CSpinner color="primary" />
      </CRow>
    </CContainer>
  </VisitorWrapper>
</template>
<script>
import VisitorErrorHandler from "@/v3components/VisitorErrorHandler.vue"
import VisitorWrapper from "@/v3components/VisitorWrapper.vue"
import VisitorSettingsControl from "@/v3components/Forms/Visitor/VisitorSettingsControl.vue"
import VisitorSettingsBody from "@/v3components/Forms/Visitor/VisitorSettingsBody.vue"
import visitorConstants from "@/constants/visitorConstants"
import { computed, defineAsyncComponent, onMounted, inject, ref } from "vue"
import { useStore } from "vuex"
import { cloneDeep } from "lodash"

const VisitorSchoolHours = defineAsyncComponent(
  () =>
    import(
      "@/v3components/Forms/Visitor/GeneralSettings/VisitorSchoolHours.vue"
    )
)

const VisitorEditReasonsForm = defineAsyncComponent(
  () =>
    import(
      "@/v3components/Forms/Visitor/KioskSettings/VisitorEditReasonsForm.vue"
    )
)

const VisitorEditDestinationsForm = defineAsyncComponent(
  () =>
    import(
      "@/v3components/Forms/Visitor/KioskSettings/VisitorEditDestinationsForm.vue"
    )
)

const VisitorEditHealthQuestionsForm = defineAsyncComponent(
  () =>
    import(
      "@/v3components/Forms/Visitor/KioskSettings/VisitorEditHealthQuestionsForm.vue"
    )
)

const VisitorQuestionsMissing = defineAsyncComponent(
  () =>
    import(
      "@/v3components/Forms/Visitor/KioskSettings/VisitorQuestionsMissing.vue"
    )
)

export default {
  name: "VisitorGeneralSettings",
  components: {
    VisitorSettingsControl,
    VisitorWrapper,
    VisitorSettingsBody,
    VisitorErrorHandler
  },
  setup() {
    const store = useStore()
    const modal = inject("modal")

    const defaultGeneralSettingValues = {
      startTime: visitorConstants.VISITOR_DEFAULT_GENERAL_SETTINGS.START_TIME,
      endTime: visitorConstants.VISITOR_DEFAULT_GENERAL_SETTINGS.END_TIME,
      timeZone: visitorConstants.VISITOR_DEFAULT_GENERAL_SETTINGS.TIME_ZONE,
      automaticCheckout:
        visitorConstants.VISITOR_DEFAULT_GENERAL_SETTINGS.AUTOMATIC_CHECKOUT
    }

    const isGeneralSettingsLoading = ref(true)
    const isLoading = ref(false)
    const generalSettingsServerError = ref(null)
    const activeSettingKey = ref(null)
    const serverError = ref(null)

    const school = computed(() => store.getters["visitorSettings/school"])

    const visitorGeneralSettings = computed(() => {
      return school?.value?.generalSettings || []
    })

    const visitorSchoolData = computed(() => {
      return school?.value?.data || null
    })

    /**
     * List of settings to be displayed on general settings page
     */
    const settingList = computed(() => [
      {
        settingsKey: "school-hours",
        heading: "School hours",
        showDescription: false,
        description: "Set default school hours",
        hasLearnMore: true,
        learnMore: "visitor_general_settings_school_hours",
        hasEdit: false,
        showEdit: false,
        hasSwitch: false,
        showSwitch: false,
        hasControl: true,
        options: {
          enabled: false,
          restricted: false
        },
        edit: async (
          schoolStartTimeValue,
          schoolEndTimeValue,
          schoolTimeZoneValue
        ) => {
          activeSettingKey.value = "school-hours"
          try {
            serverError.value = null
            isLoading.value = true
            await editSchoolHours(
              schoolStartTimeValue,
              schoolEndTimeValue,
              schoolTimeZoneValue
            )
          } catch (error) {
            activeSettingKey.value = "school-hours"
            serverError.value = error
          } finally {
            isLoading.value = false
          }
        },
        toggle: () => ({})
      },
      {
        settingsKey: "custom-reasons",
        heading: "Customized reasons for visit",
        description:
          "Add custom reasons for visitors to select from when visiting a school (if not customized, defaults will be used)",
        showDescription: false,
        hasLearnMore: true,
        learnMore: "visitor_kiosk_settings_reasons_learn_more",
        hasEdit: true,
        showEdit: true,
        hasSwitch: false,
        showSwitch: false,
        hasBody: true,
        options: {
          enabled: true,
          restricted: false
        },
        edit: () => {
          modal.open(VisitorEditReasonsForm, {
            size: "lg",
            title:
              visitorConstants.KIOSK_SETTINGS
                .CUSTOMIZE_EDIT_REASONS_MODAL_TITLE,
            props: {}
          })
        },
        toggle: () => ({})
      },
      {
        settingsKey: "custom-destinations",
        heading: "Customized list of destinations",
        description:
          "Add or edit destinations for visitors to select from when visiting a school.",
        showDescription: false,
        hasLearnMore: true,
        learnMore: "visitor_kiosk_settings_destinations_learn_more",
        hasEdit: true,
        showEdit: isSchoolSettingActive(visitorConstants.SETTINGS.DESTINATION),
        hasSwitch: true,
        showSwitch: true,
        hasBody: true,
        options: {
          enabled: isSchoolSettingActive(visitorConstants.SETTINGS.DESTINATION),
          restricted: false
        },
        edit: () => {
          modal.open(VisitorEditDestinationsForm, {
            size: "lg",
            title:
              visitorConstants.KIOSK_SETTINGS
                .CUSTOMIZE_EDIT_DESTINATIONS_MODAL_TITLE,
            props: {}
          })
        },
        toggle: async () => {
          activeSettingKey.value = "custom-destinations"
          try {
            isLoading.value = true
            await toggleSchoolSetting(
              visitorConstants.SETTINGS.DESTINATION,
              isSchoolSettingActive(visitorConstants.SETTINGS.DESTINATION)
            )
          } catch (error) {
            activeSettingKey.value = "custom-destinations"
            serverError.value = error
          } finally {
            isLoading.value = false
          }
        }
      },
      {
        settingsKey: "visitor-health-screening",
        heading: visitorConstants.KIOSK_SETTINGS.HEALTH_SCREENING_SETTING_TITLE,
        description: "Screen visitors health for illnesses such as COVID-19.",
        showDescription: false,
        hasLearnMore: true,
        learnMore: "visitor_kiosk_settings_health_screening_learn_more",
        hasEdit: true,
        showEdit: isSchoolSettingActive(
          visitorConstants.SETTINGS.HEALTH_SCREENING
        ),
        hasSwitch: true,
        showSwitch: true,
        hasBody: true,
        options: {
          enabled: isSchoolSettingActive(
            visitorConstants.SETTINGS.HEALTH_SCREENING
          ),
          restricted: isHealthScreeningRestricted()
        },
        edit: () => {
          modal.open(VisitorEditHealthQuestionsForm, {
            size: "lg",
            title:
              visitorConstants.KIOSK_SETTINGS
                .CUSTOMIZE_HEALTH_SCREENING_MODAL_TITLE,
            props: {}
          })
        },
        toggle: async () => {
          const notActive = !isSchoolSettingActive(
            visitorConstants.SETTINGS.HEALTH_SCREENING
          )
          if (notActive) {
            const noActiveQuestions = areNoHealthScreenQuestionsActive()
            if (noActiveQuestions) {
              modal.open(VisitorQuestionsMissing, {
                size: "sm",
                props: {
                  add: () => {
                    modal.open(VisitorEditHealthQuestionsForm, {
                      size: "lg",
                      title:
                        visitorConstants.KIOSK_SETTINGS
                          .CUSTOMIZE_HEALTH_SCREENING_MODAL_TITLE,
                      props: {
                        add: () => {
                          modal.open(VisitorEditHealthQuestionsForm, {
                            size: "lg",
                            title:
                              visitorConstants.KIOSK_SETTINGS
                                .CUSTOMIZE_HEALTH_SCREENING_MODAL_TITLE,
                            props: {}
                          })
                        }
                      }
                    })
                  }
                }
              })
              return
            }
          }
          activeSettingKey.value = "visitor-health-screening"
          try {
            isLoading.value = true
            await toggleSchoolSetting(
              visitorConstants.SETTINGS.HEALTH_SCREENING,
              isSchoolSettingActive(visitorConstants.SETTINGS.HEALTH_SCREENING)
            )
          } catch (error) {
            activeSettingKey.value = "visitor-health-screening"
            serverError.value = error
          } finally {
            isLoading.value = false
          }
        }
      },

      {
        settingsKey: "enable-phone-number-entry",
        heading: "Phone number required (Enable phone number entry)",
        description:
          "Require visitors to input their phone numbers on their first visit.",
        showDescription: false,
        hasLearnMore: true,
        learnMore: "visitor_kiosk_settings_phone_number_entry_learn_more",
        hasEdit: false,
        showEdit: false,
        hasSwitch: true,
        showSwitch: true,
        hasBody: false,
        options: {
          enabled: isSchoolSettingActive(
            visitorConstants.SETTINGS.PHONE_NUMBER_ENTRY
          ),
          restricted: false
        },
        edit: () => ({}),
        toggle: async () => {
          activeSettingKey.value = "enable-phone-number-entry"
          try {
            isLoading.value = true
            await toggleSchoolSetting(
              visitorConstants.SETTINGS.PHONE_NUMBER_ENTRY,
              isSchoolSettingActive(
                visitorConstants.SETTINGS.PHONE_NUMBER_ENTRY
              )
            )
          } catch (error) {
            activeSettingKey.value = "enable-phone-number-entry"
            serverError.value = error
          } finally {
            isLoading.value = false
          }
        }
      },
      {
        settingsKey: "photo-capture",
        heading: "Photo capture",
        description:
          "Allow or deny the photo capture feature for new visitors.",
        showDescription: false,
        hasLearnMore: true,
        learnMore: "visitor_kiosk_settings_photo_capture_learn_more",
        hasEdit: false,
        showEdit: false,
        hasSwitch: true,
        showSwitch: true,
        hasBody: false,
        options: {
          enabled: isSchoolSettingActive(
            visitorConstants.SETTINGS.PHOTO_CAPTURE
          ),
          restricted: false
        },
        edit: () => ({}),
        toggle: async () => {
          activeSettingKey.value = "photo-capture"
          try {
            isLoading.value = true
            await toggleSchoolSetting(
              visitorConstants.SETTINGS.PHOTO_CAPTURE,
              isSchoolSettingActive(visitorConstants.SETTINGS.PHOTO_CAPTURE)
            )
          } catch (error) {
            activeSettingKey.value = "photo-capture"
            serverError.value = error
          } finally {
            isLoading.value = false
          }
        }
      },
      {
        settingsKey: "pre-approved-visitor",
        heading: "Pre-set visitor (Pre-approved visitor)",
        description:
          "Enable or disable to allow only pre-set visitors to check-in.",
        showDescription: false,
        hasLearnMore: true,
        learnMore: "visitor_kiosk_settings_pre_approved_visitor_learn_more",
        hasEdit: false,
        showEdit: false,
        hasSwitch: true,
        showSwitch: true,
        hasBody: false,
        options: {
          enabled: isSchoolSettingActive(
            visitorConstants.SETTINGS.PRE_APPROVED_VISITOR
          ),
          restricted: false
        },
        edit: () => ({}),
        toggle: async () => {
          activeSettingKey.value = "pre-approved-visitor"
          try {
            isLoading.value = true
            await toggleSchoolSetting(
              visitorConstants.SETTINGS.PRE_APPROVED_VISITOR,
              isSchoolSettingActive(
                visitorConstants.SETTINGS.PRE_APPROVED_VISITOR
              )
            )
          } catch (error) {
            activeSettingKey.value = "pre-approved-visitor"
            serverError.value = error
          } finally {
            isLoading.value = false
          }
        }
      },
      {
        settingsKey: "automatic-checkout",
        heading: "Automatic checkout",
        showDescription: false,
        description:
          "Enable or disable automatic checkout. If enabled, visitors will automatically be checked out at the end of the each school day",
        hasLearnMore: true,
        learnMore: "visitor_general_settings_automatic_checkout",
        hasEdit: false,
        showEdit: false,
        hasSwitch: true,
        showSwitch: true,
        hasControl: false,
        options: {
          enabled: isGeneralSettingActive(
            visitorConstants.SETTINGS.AUTOMATIC_CHECKOUT
          ),
          restricted: false
        },
        edit: () => ({}),
        toggle: async () => {
          serverError.value = null
          activeSettingKey.value = "automatic-checkout"
          try {
            isLoading.value = true
            await toggleAutomaticCheckout(
              !isGeneralSettingActive(
                visitorConstants.SETTINGS.AUTOMATIC_CHECKOUT
              )
                ? "true"
                : "false"
            )
          } catch (error) {
            activeSettingKey.value = "automatic-checkout"
            serverError.value = error
          } finally {
            isLoading.value = false
          }
        }
      }
    ])

    const getGeneralSetting = (key) => {
      return (
        visitorGeneralSettings.value?.find(
          (setting) => setting?.settings_id === key
        ) || null
      )
    }

    const isGeneralSettingActive = (key) => {
      return getGeneralSetting(key)?.value === "true"
    }

    const getSchoolSetting = (key) => {
      return (
        visitorSchoolData?.value?.building_settings?.find(
          (setting) => setting?.settings_id === key
        ) || null
      )
    }

    const isSchoolSettingActive = (key) => {
      return getSchoolSetting(key)?.value === "true" || false
    }

    const toggleSchoolSetting = async (key, value) => {
      const buildingSetting = cloneDeep(getSchoolSetting(key))
      if (!buildingSetting) {
        return
      }
      buildingSetting.value = value ? "false" : "true"
      await store.dispatch("visitorSettings/updateSchoolSettings", {
        building_settings: [buildingSetting]
      })
      await store.dispatch("visitorSettings/getSchoolSettings")
    }

    const isHealthScreeningRestricted = () => {
      const alreadyEnabled = isSchoolSettingActive(
        visitorConstants.SETTINGS.HEALTH_SCREENING
      )
      if (alreadyEnabled) {
        return false
      }

      return areNoHealthScreenQuestionsActive()
    }

    const areNoHealthScreenQuestionsActive = () => {
      const activeHealthScreeningQuestionList =
        visitorSchoolData.value?.health_screening_questions?.filter(
          (question) => Number(question?.active) === 1
        ) || []
      return activeHealthScreeningQuestionList.length === 0
    }

    const toggleAutomaticCheckout = async (value) => {
      const data = [
        {
          settings_id: visitorConstants.SETTINGS.AUTOMATIC_CHECKOUT,
          value: value
        },
        {
          settings_id: visitorConstants.SETTINGS.SCHOOL_START_TIME,
          value:
            getGeneralSetting(visitorConstants.SETTINGS.SCHOOL_START_TIME)
              ?.value || defaultGeneralSettingValues.startTime
        },
        {
          settings_id: visitorConstants.SETTINGS.SCHOOL_END_TIME,
          value:
            getGeneralSetting(visitorConstants.SETTINGS.SCHOOL_END_TIME)
              ?.value || defaultGeneralSettingValues.endTime
        },
        {
          settings_id: visitorConstants.SETTINGS.SCHOOL_TIMEZONE,
          value:
            getGeneralSetting(visitorConstants.SETTINGS.SCHOOL_TIMEZONE)
              ?.value || defaultGeneralSettingValues.timeZone
        }
      ]
      await store.dispatch("visitorSettings/updateSchoolGeneralSettings", {
        general_settings: data
      })
      await store.dispatch("visitorSettings/getSchoolGeneralSettings")
    }

    const editSchoolHours = async (
      schoolStartTimeValue,
      schoolEndTimeValue,
      schoolTimeZoneValue
    ) => {
      const data = [
        {
          settings_id: visitorConstants.SETTINGS.SCHOOL_START_TIME,
          value: schoolStartTimeValue
        },
        {
          settings_id: visitorConstants.SETTINGS.SCHOOL_END_TIME,
          value: schoolEndTimeValue
        },
        {
          settings_id: visitorConstants.SETTINGS.SCHOOL_TIMEZONE,
          value: schoolTimeZoneValue
        },
        {
          settings_id: visitorConstants.SETTINGS.AUTOMATIC_CHECKOUT,
          value:
            getGeneralSetting(visitorConstants.SETTINGS.AUTOMATIC_CHECKOUT)
              ?.value || defaultGeneralSettingValues.automaticCheckout
        }
      ]
      await store.dispatch("visitorSettings/updateSchoolGeneralSettings", {
        general_settings: data
      })
      await store.dispatch("visitorSettings/getSchoolGeneralSettings")
    }

    const getSettingsKey = (key) => {
      if (["custom-destinations", "visitor-health-screening"].includes(key)) {
        return key + isLoading.value
      }
      return key
    }

    onMounted(async () => {
      try {
        isGeneralSettingsLoading.value = true
        await store.dispatch("visitorSettings/getSchoolSettings")
        await store.dispatch("visitorSettings/getSchoolGeneralSettings")
      } catch (error) {
        generalSettingsServerError.value = error
      } finally {
        isGeneralSettingsLoading.value = false
      }
    })

    return {
      isLoading,
      isGeneralSettingsLoading,
      activeSettingKey,
      generalSettingsServerError,
      serverError,
      settingList,
      VisitorSchoolHours,
      getSettingsKey
    }
  }
}
</script>
