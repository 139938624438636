<template>
  <VisitorWrapper>
    <!-- SERVER ERROR -->
    <VisitorErrorHandler
      v-if="serverError != null"
      :error="serverError"
      @done="serverError = null"
    />
    <!-- SERVER ERROR -->

    <CContainer v-if="!isLoading">
      <!-- SIGNED IN VISITORS - MODAL CONTAINER -->
      <VisitorSignedInVisitors />
      <!-- SIGNED IN VISITORS - MODAL CONTAINER -->

      <!-- PAGE HEADER -->
      <VisitorCheckInsAndLogsHeader />
      <!-- PAGE HEADER -->

      <!-- VISITOR CHECK-IN FORM -->
      <CRow
        class="justify-content-center align-content-center m-0 mt-4 bg-white rounded-30 p-4 d-flex flex-column"
      >
        <VisitorCheckInForm class="min-h-45vh" />
      </CRow>
      <!-- VISITOR CHECK-IN FORM -->

      <!-- VISITOR LOG HISTORY TABLE -->
      <CRow class="justify-content-center mt-4 d-flex flex-column gap-2">
        <VisitorLogHistoryTable />
      </CRow>
      <!-- VISITOR LOG HISTORY TABLE -->
    </CContainer>

    <CContainer v-else>
      <CRow
        class="justify-content-center align-content-center mt-4 rounded-30 p-5 d-flex gap-2"
      >
        <CSpinner color="primary" />
      </CRow>
    </CContainer>
  </VisitorWrapper>
</template>

<script>
import VisitorWrapper from "@/v3components/VisitorWrapper.vue"
import VisitorErrorHandler from "@/v3components/VisitorErrorHandler.vue"
import VisitorCheckInsAndLogsHeader from "@/v3components/Forms/Visitor/CheckIns/VisitorCheckInsAndLogsHeader.vue"
import VisitorLogHistoryTable from "@/v3components/Datatables/Visitor/CheckIns/VisitorLogHistoryTable.vue"
import VisitorSignedInVisitors from "@/v3components/Datatables/Visitor/CheckIns/VisitorSignedInVisitors.vue"
import VisitorCheckInForm from "@/v3components/Forms/Visitor/CheckIns/VisitorCheckInForm.vue"
import { onMounted, ref } from "vue"
import { useStore } from "vuex"

export default {
  name: "VisitorCheckInsAndLogs",
  components: {
    VisitorWrapper,
    VisitorCheckInsAndLogsHeader,
    VisitorLogHistoryTable,
    VisitorCheckInForm,
    VisitorSignedInVisitors,
    VisitorErrorHandler
  },
  setup() {
    const store = useStore()
    const isLoading = ref(true)
    const serverError = ref(null)

    onMounted(async () => {
      try {
        isLoading.value = true
        await store.dispatch("visitorSettings/getSchoolGeneralSettings")
        await store.dispatch("visitorSettings/getSchoolSettings")
        await store.dispatch("visitorManage/getReasons")
        await store.dispatch("visitorManage/getDestinations")
        await store.dispatch("visitorManage/getReasonLookups")
        await store.dispatch("visitorSettings/getPrinters")
      } catch (error) {
        serverError.value = error
      } finally {
        isLoading.value = false
      }
    })

    return {
      isLoading,
      serverError
    }
  }
}
</script>

<style scoped>
.min-h-45vh {
  min-height: 45vh;
}
</style>
